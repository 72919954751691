<template>
  <fragment>
    <div class="cartSection">
      <div class="vertical-imgs">
        <img alt="Картинка товара" class="item" :src="getProductImageSrc(line.productImageId)">
      </div>
    </div>
    <div class="cartSection">
      <p class="infoHead">Товар</p>
      <p>
        <span :class="{'not-available-item': itemNotAvailable}">
          {{line.productName}} <span v-if="line.quantityInBox > 1">({{line.quantityInBox}} шт.)</span>
          <span v-if="line.sizeName" class="size-name">{{line.sizeName}}</span>
        </span>
      </p>
      <div class="infoWrap">
        <div class="d-table-cell">
          <p class="infoHead">Цена</p>
          <span :class="{'not-available-item': itemNotAvailable}">
            {{line.price | fractionSizeSpace}} {{makeDeclension(line.price, "балл", "балла", "баллов")}}
          </span>
        </div>
        <div class="d-table-cell">
          <p class="infoHead"> Количество</p>
          <product-quantity
            :style="itemNotAvailable ? 'visibility: hidden;' : 'visibility: visible;'"
            :product-id="line.productId"
            :size-id="line.sizeId"
            :quantity="line.quantity"
            :item-not-available="itemNotAvailable"
          />
        </div>
        <div class="d-table-cell">
          <p class="infoHead"> Сумма</p>
          <span :class="{'not-available-item': itemNotAvailable}">
              {{line.price * line.quantity | fractionSizeSpace}} {{makeDeclension(line.price * line.quantity, "балл", "балла", "баллов")}}
          </span>
        </div>
      </div>
    </div>
    <div class="cartSection removeWrap text-center w-190">
      <button
        :class="{'not-available-item': itemNotAvailable}"
        class="close-oval"
        :disabled="isCartUpdating"
        @click="removeProductUpdateLine({productId: line.productId, sizeId: line.sizeId, completely: true})"
      >
        <span class="close">&times;</span>
      </button>
      <span v-if="!line.productIsAvailable || line.productIsHidden " class="d-block not-available-item">
        Временно отсутствует
      </span>
    </div>
  </fragment>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CartLineProductQuantityMobile from "./CartLineProductQuantity";

export default {
  name: "CartLineDesktop",
  components: {
    'product-quantity': CartLineProductQuantityMobile
  },
  props: {
    line: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('common', ['getProductImageSrc', 'makeDeclension']),
    ...mapGetters('cart/dealer', ['isCartUpdating']),
    itemNotAvailable() {
      return !!(!this.line.productIsAvailable || this.line.productIsHidden);
    }
  },
  methods: {
    ...mapActions('cart/dealer', ['removeProductUpdateLine'])
  }
}
</script>

<style scoped>

.infoHead {
  font-size: 14px;
  border-top: 0;
  font-weight: bold;
}

.item {
  width: 100%;
  height: 100%;
  /*margin-top: 16px;*/
}

.close-oval {
  width: 26px;
  height: 26px;
  background-color: #4a4a4a;
  border: none;
  border-radius: 50%;
  margin-top: 6px;
  cursor: pointer;
}

.close {
  font-size: 20px;
  font-weight: normal;
  font-family: Serif;
  color: white;
  position: relative;
  opacity: 1;
  top: -1px;
  text-align: center;
  left: -1px;
}

.vertical-imgs {
  width: 60px;
  height: 77px;
  /*padding-bottom: 25px;*/
}

.not-available-item{
  color: red;
}

/*.w-190{*/
/*  width: 190px;*/
/*}*/

@media (max-width: 767px) {
  .infoWrap {
    display: table;
    width: 100%;
  }

  .infoWrap:first-child {
    padding-bottom: 20px;
    border-bottom: 2px solid #fafbfd;
  }

  .cartSection {
    display: table-cell;
  }

  .cartSection:first-child {
    vertical-align: middle;
  }
}
</style>
